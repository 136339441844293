.card-color-home {
    background-image: linear-gradient(to top, white, #EEEEEE);
    text-align: center;
    color: white;
    padding: 200px 0;
}

.card-color-home > #title {
    background: linear-gradient(to bottom right, #FFE700, #FF6900);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 5em;
    font-weight: bolder;
    margin-bottom: 30px;
}

.card-color-home > #content {
    color: black;
    font-size: 2em;
    margin: 50px;
}

.card-default {
    background-color: white;
    text-align: center;
    color: black;
    padding: 100px 0;
}

.card-default > #title {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 20px;
}

.card-default > #content {
    font-size: 1.25em;
    margin: 20px 100px;
}

.card-color-home-sub {
    background-image: linear-gradient(to bottom left, #FFD700, #FF9900);
    text-align: center;
    color: white;
    padding: 100px 0;
}

.card-color-home-sub > #title {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 20px;
}

.card-color-home-sub > #content {
    font-size: 1.25em;
    margin: 20px 100px;
}
